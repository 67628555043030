#subscribe-toplayer {

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	//background: rgba(0, 0, 0, 0.7);
	//background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.8) 100%);
	transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;

	font-family: 'Calibri', 'Segoe UI', Helvetica, Arial, sans-serif;
	color: #333;

	opacity: 0;
	z-index: -10;

	&.visible {
		opacity: 1;
		z-index: 9999999999;
	}

	/*&.mobile.firefox,
	&.mobile.opera {
		display: none !important;
	}*/

	.container {

		background: #fff;
		display: flex;
		flex-direction: column;
		//padding: 20px;

		> .header {

			display: flex;
			padding: 20px;
			z-index: 2;
			//align-items: center;

			.brand-image {
				height: 50px;
				width: auto;

				img {
					width: auto;
					height: 100%;
				}
			}

			.close {

				margin-top: -0;
				margin-right: -0;
				margin-left: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 16px;
				height: 16px;
				line-height: 22px;
				font-size: 22px;
				color: #888;
				cursor: pointer;

				&:hover {
					color: #000;
				}

			}

		}

		.image {

			display: flex;
			align-items: center;
			justify-content: center;

			img {
				display: block;
				margin: 0 auto;
				width: auto;
				max-width: 100%;
				max-height: 250px;
			}

			svg {

				width: 100px;
				height: 100px;

				.background {
					fill: rgb(127, 127, 127);
				}

				.foreground {
					fill: #fff;
				}

				.stroke {
					stroke: #fff;
					stroke-width: 2px;
					fill: transparent;
				}

			}

		}

		.content {

			padding: 35px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;

			.header {
				font-size: 26px;
				line-height: 28px;
				font-weight: 400;
				margin-bottom: 5px;
			}

			.text {
				font-size: 18px;
				line-height: 22px;
				margin-top: 4px;
			}

			.cta {

				display: block;
				margin-top: 30px;
				margin-bottom: 10px;
				align-self: center;
				padding: 10px 28px;
				border-radius: 3px;
				font-size: 18px;
				color: #fff;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}

			}

			.footer-text {

				font-size: 14px;
				line-height: 16px;
				margin-top: 5px;
				color: #aaa;
				align-self: center;

				&.read-more {

					text-decoration: none;
					border-bottom: 1px solid #ddd;

					&:hover {
						color: #333;
					}

				}

			}

		}

	}

}
