.onesignal-popover-container {

	.popover-body-icon {

		img {
			display: none;
		}

		.onesignal-bell-svg {

			width: 90%;
			height: 90%;
			opacity: 0;
			transform: scale(0.1);
			transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

			&.show {
				opacity: 1;
				transform: scale(1);
			}

			.background {
				fill: rgb(127, 127, 127);
			}

			.foreground {
				fill: #fff;
			}

			.stroke {
				stroke: #fff;
				stroke-width: 2px;
				fill: transparent;
			}

		}

	}

}
