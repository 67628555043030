#permission-prompt-toplayer {

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	//background: rgba(0, 0, 0, 0.7);
	//background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.8) 100%);
	transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;

	font-family: 'Calibri', 'Segoe UI', Helvetica, Arial, sans-serif;
	color: #fff;

	opacity: 0;
	z-index: -10;

	&.visible {
		opacity: 1;
		z-index: 9999999999;
	}

	&.mobile.firefox,
	&.mobile.opera {
		display: none !important;
	}

	.brand-image {
		width: 130px;
		height: auto;
		margin-bottom: 15px;

		display: block;
		opacity: 0;
		transform: translate3d(0, -80px, 0);
		transition: 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;

		@at-root #permission-prompt-toplayer.visible .brand-image {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	.text {
		font-size: 22px;
		line-height: 26px;
		font-weight: 400;

		display: block;
		opacity: 0;
		transform: translate3d(0, -40px, 0);
		transition: 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;

		@at-root #permission-prompt-toplayer.visible .text {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	.subtext {
		font-size: 18px;
		line-height: 22px;
		margin-top: 15px;

		display: block;
		opacity: 0;
		transform: translate3d(0, 40px, 0);
		transition: 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;

		&.second {
			transform: translate3d(0, 80px, 0);
		}

		@at-root #permission-prompt-toplayer.visible .subtext {
			opacity: 0.5;
			transform: translate3d(0, 0, 0);
		}
	}

	.arrow {

		display: none;

		@at-root #permission-prompt-toplayer.visible.desktop.chrome .arrow,
		#permission-prompt-toplayer.visible.desktop.firefox .arrow {
			display: block;
			position: absolute;
			transform: rotate(120deg) scaleX(-1);
			top: 120px;
			left: 280px;
		}

		@at-root #permission-prompt-toplayer.visible.desktop.firefox .arrow {
			left: 320px;
		}

		@at-root #permission-prompt-toplayer.visible.desktop.opera .arrow {
			order: 1;
			display: block;
			transform: rotate(240deg);
		}
		@at-root #permission-prompt-toplayer.visible.desktop.opera .text {order: 2;}
		@at-root #permission-prompt-toplayer.visible.desktop.opera .subtext {order: 3;}

		@at-root #permission-prompt-toplayer.visible.mobile.chrome .arrow,
		#permission-prompt-toplayer.visible.mobile.samsungbrowser .arrow {
			display: block;
			align-self: flex-end;
			margin-top: 50px;
		    transform: rotate(-60deg) scaleX(-1);
		}

		svg {
			width: 220px;
			height: 220px;

			.draw-arrow {
				stroke-width: 2;
				fill: none;
				animation-fill-mode: forwards;
				animation-iteration-count: once;
				animation-name: draw;
				animation-timing-function: linear;
				stroke-dasharray: 400;
				stroke-dashoffset: 400;
				animation-duration: 4s;
				animation-delay: 1s;

				&.tail-1 {
					animation-delay: 1.95s;
				}

				&.tail-2 {
					animation-delay: 2.2s;
				}

			}

			@keyframes draw {
				to {
					stroke-dashoffset: 0;
				}
			}

		}

	}

}
